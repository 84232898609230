import { notEmpty, PageType } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import { checkIsPlusArticle } from "scmp-app/components/article/article-render/plus/helpers";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { DataMap } from "scmp-app/components/plus/plus-article-type-title/consts";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { plusWidgetHighlight$key } from "scmp-app/queries/__generated__/plusWidgetHighlight.graphql";

import {
  Container,
  CoverLink,
  Headline,
  HeadlineLink,
  StyledPlusArticleTypeLink,
  TypeOrSectionLabel,
} from "./styles";

export type Props = {
  reference: plusWidgetHighlight$key;
  withArticleType?: boolean;
} & ContentItemRenderVariantProps;

export const ContentItemPlusHighlight = forwardRef<HTMLDivElement, Props>(
  ({ className, reference: reference_, withArticleType = true }, reference) => {
    const entity = useFragment(
      graphql`
        fragment plusWidgetHighlight on Content {
          __typename
          entityId
          urlAlias
          ...entityLink
          ...helpersCheckIsPlusArticle
          ...helpersGetArticleTypeArticle
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withCoverImage: true
              withCoverImageSize540x360: true
              withSections: true
            )
          ...plusArticleTypeTitleContent
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(entity);
    const articleTypeId = getArticleType(entity) ?? "";
    const { title, type } = DataMap[articleTypeId] ?? {};
    const isPlusArticle = checkIsPlusArticle(entity);
    const currentPageType = useCurrentPageType();
    const pageType = currentPageType === PageType.Homepage ? "homepage" : "section";

    const handleRenderArticleTypeOrSections = () => {
      if (isPlusArticle || type === "infographic") {
        return type === "infographic" ? (
          <TypeOrSectionLabel>{title}</TypeOrSectionLabel>
        ) : (
          withArticleType && notEmpty(title) && (
            <StyledPlusArticleTypeLink
              pathname={`/plus?plus-detail=${type}&module=content_discovery&pgtype=${pageType}`}
            >
              {title}
            </StyledPlusArticleTypeLink>
          )
        );
      }
      return <TypeOrSectionLabel>{providers.sections({ isDisableLink: true })}</TypeOrSectionLabel>;
    };

    const handleItemClickEvent = () => {
      sendGA4Tracking({
        action: "click",
        category: "recirculation",
        customized_parameters: {
          article_id: entity?.entityId ?? "",
          block_section: "highlight_from_plus",
          destination_url: entity?.urlAlias ?? "",
          page_type: pageType,
          widget_name: "content_discovery",
        },
        subcategory: "widget",
      });
    };

    return (
      <Container className={className} ref={reference}>
        {handleRenderArticleTypeOrSections()}

        <HeadlineLink
          query={{ display: "plus", module: "content_discovery", pgtype: pageType }}
          reference={entity}
          onClick={handleItemClickEvent}
        >
          <Headline>
            {providers.headline({
              hideTooltip: true,
              preferSocialHeadline: true,
            })}
          </Headline>
        </HeadlineLink>
        <CoverLink
          query={{ display: "plus", module: "content_discovery", pgtype: pageType }}
          reference={entity}
          onClick={handleItemClickEvent}
        >
          {providers.coverImage({
            responsiveVariants: {
              desktopUp: "size540x360",
              mobileUp: "size540x360",
              tabletUp: "size540x360",
            },
          })}
        </CoverLink>
      </Container>
    );
  },
);

ContentItemPlusHighlight.displayName = "ContentItemPlusHighlight";
