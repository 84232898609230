import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { forwardRef, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { SectionTopWidgetOne } from "scmp-app/components/section/section-top/variant/one";
import { SectionTopWidgetTwo } from "scmp-app/components/section/section-top/variant/two";
import { SubsectionMenu } from "scmp-app/components/subsection-menu";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { sectionTopQuery$key } from "scmp-app/queries/__generated__/sectionTopQuery.graphql";

import { Container } from "./styles";

type Section = "china" | "hong-kong" | "lifestyle" | "people-culture" | "style" | "twia";

type Props = {
  className?: string;
  reference: sectionTopQuery$key;
  section: Section;
};

export const HomeSectionTop = forwardRef<HTMLDivElement, Props>(
  ({ className, reference, section }, elementReference) => {
    const data = useFragment(
      graphql`
        fragment sectionTopQuery on Query
        @argumentDefinitions(
          isAsiaEdition: { type: "Boolean!" }
          excludeSsrContentEntityIds: { type: "[String]" }
        ) {
          chinaSection: section(filter: { entityId: "4" }) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 5) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
          hongKongSection: section(filter: { entityId: "2" }) @skip(if: $isAsiaEdition) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 6) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
          lifestyleSection: section(filter: { entityId: "94" }) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 4) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
          peopleCultureSection: section(filter: { entityId: "318202" }) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 5) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
          styleSection: section(filter: { entityId: "72" }) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 7) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
          twiaSection: section(filter: { entityId: "323045" }) @include(if: $isAsiaEdition) {
            ...subsectionMenuSection
            name
            subSections {
              items(first: 6) {
                edges {
                  ...subsectionMenuQueue
                }
              }
            }
            topStories {
              items(first: 7, exclude: { entityIds: $excludeSsrContentEntityIds }) {
                edges {
                  ...oneSectionTopWidgetQueue
                  ...twoSectionTopWidgetQueue
                  node {
                    ... on Content {
                      entityId
                    }
                  }
                }
              }
            }
          }
        }
      `,
      reference,
    );

    const { listOfArticles, SectionBlockComponent, sectionContents, sectionEntity, subSections } =
      useMemo(() => {
        switch (section) {
          case "china":
            return {
              SectionBlockComponent: SectionTopWidgetOne,
              listOfArticles: (
                data.chinaSection?.topStories?.items?.edges?.map(item => item.node.entityId) ?? []
              ).join(","),
              sectionContents: data.chinaSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.chinaSection,
              subSections: data.chinaSection?.subSections?.items?.edges ?? [],
            };
          case "hong-kong":
            return {
              SectionBlockComponent: SectionTopWidgetOne,
              listOfArticles: (
                data.hongKongSection?.topStories?.items?.edges?.map(item => item.node.entityId) ??
                []
              ).join(","),
              sectionContents: data.hongKongSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.hongKongSection,
              subSections: data.hongKongSection?.subSections?.items?.edges ?? [],
            };
          case "lifestyle":
            return {
              SectionBlockComponent: SectionTopWidgetTwo,
              listOfArticles: (
                data.lifestyleSection?.topStories?.items?.edges?.map(item => item.node.entityId) ??
                []
              ).join(","),
              sectionContents: data.lifestyleSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.lifestyleSection,
              subSections: data.lifestyleSection?.subSections?.items?.edges ?? [],
            };
          case "people-culture":
            return {
              SectionBlockComponent: SectionTopWidgetTwo,
              listOfArticles: (
                data.peopleCultureSection?.topStories?.items?.edges?.map(
                  item => item.node.entityId,
                ) ?? []
              ).join(","),
              sectionContents: data.peopleCultureSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.peopleCultureSection,
              subSections: data.peopleCultureSection?.subSections?.items?.edges ?? [],
            };
          case "style":
            return {
              SectionBlockComponent: SectionTopWidgetTwo,
              listOfArticles: (
                data.styleSection?.topStories?.items?.edges?.map(item => item.node.entityId) ?? []
              ).join(","),
              sectionContents: data.styleSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.styleSection,
              subSections: data.styleSection?.subSections?.items?.edges ?? [],
            };
          case "twia":
            return {
              SectionBlockComponent: SectionTopWidgetOne,
              listOfArticles: (
                data.twiaSection?.topStories?.items?.edges?.map(item => item.node.entityId) ?? []
              ).join(","),
              sectionContents: data.twiaSection?.topStories?.items?.edges ?? [],
              sectionEntity: data.twiaSection,
              subSections: data.twiaSection?.subSections?.items?.edges ?? [],
            };
        }
      }, [
        data.chinaSection,
        data.hongKongSection,
        data.lifestyleSection,
        data.peopleCultureSection,
        data.styleSection,
        data.twiaSection,
        section,
      ]);

    const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
      () => ({
        action: "imp",
        category: "recirculation",
        customized_parameters: {
          block_section: sectionEntity?.name,
          list_of_articles: listOfArticles,
          page_type: "homepage",
          widget_name: "homepage_section_block",
        },
        subcategory: "widget",
      }),
      [sectionEntity?.name, listOfArticles],
    );

    const { captureTrackImpressionEventTargetElement } =
      useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
        ga4TrackingHandler: sendGA4Tracking,
        getGa4Event: impressionGA4Event,
        options: { isSendGA4Tracking: true, shouldSendOnce: true },
      });

    return (
      <BaseLinkContextProvider
        customQueryParameters={{
          module: `hp_section_${section}`,
        }}
      >
        <Container className={className} ref={elementReference}>
          <span ref={captureTrackImpressionEventTargetElement} />
          {subSections && sectionEntity && (
            <SubsectionMenu reference={sectionEntity} subSectionReference={subSections} />
          )}
          {sectionContents && (
            <SectionBlockComponent
              reference={sectionContents}
              primaryWithTopic
              onClick={(entityId, urlAlias) => {
                sendGA4Tracking({
                  action: "click",
                  category: "recirculation",
                  customized_parameters: {
                    article_id: entityId,
                    block_section: sectionEntity?.name,
                    destination_url: getAbsoluteUrl(urlAlias),
                    page_type: "homepage",
                    widget_name: "homepage_section_block",
                  },
                  subcategory: "widget",
                });
              }}
            />
          )}
        </Container>
      </BaseLinkContextProvider>
    );
  },
);

HomeSectionTop.displayName = "HomeSectionTop";
