import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { EntityLink } from "scmp-app/components/entity-link";

export const HeadlineLink = styled(EntityLink)`
  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const Headline = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
`;

export const StyledPlusArticleTypeLink = styled(BaseLink)`
  color: #8ab2ff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.06px;
  text-transform: uppercase;
  text-decoration: underline;
`;

export const TypeOrSectionLabel = styled.div`
  color: #8ab2ff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.06px;
  text-transform: uppercase;
`;

export const CoverLink = styled(EntityLink)`
  display: block;

  border-radius: 4px;

  cursor: pointer;

  overflow: hidden;
  aspect-ratio: 16 / 9;

  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 208 / 138.67;

    max-inline-size: 208px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    aspect-ratio: 16 / 9;

    max-inline-size: none;
  }

  img {
    border-radius: 4px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  font-family: ${fontRobotoCondensed};

  &:has(${CoverLink}:hover) {
    ${Headline} {
      text-decoration: underline;
    }
  }
`;
