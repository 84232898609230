/**
 * @generated SignedSource<<10df89add1af9d74dfcc8ea6609bd4da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksHomeStoryPackageTopicQueue$data = {
  readonly refTerms: ReadonlyArray<{
    readonly __typename: "Newsletter";
    readonly latestArticleLink: string | null | undefined;
    readonly name: string;
  } | {
    readonly __typename: "Section";
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
  } | {
    readonly __typename: "Topic";
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "followButtonBase">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined> | null | undefined;
  readonly settings: unknown | null | undefined;
  readonly " $fragmentType": "hooksHomeStoryPackageTopicQueue";
};
export type hooksHomeStoryPackageTopicQueue$key = {
  readonly " $data"?: hooksHomeStoryPackageTopicQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksHomeStoryPackageTopicQueue">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "entityLink"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "followButtonBase"
  },
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksHomeStoryPackageTopicQueue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "refTerms",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestArticleLink",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "Newsletter",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Section",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Topic",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    }
  ],
  "type": "Queue",
  "abstractKey": null
};
})();

(node as any).hash = "666b1368a2efc698d6248be347c8f4b7";

export default node;
