import { FollowType, notEmpty, PageType } from "@product/scmp-sdk";
import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { sectionTopLeadSecondaryContentItemContent$key } from "scmp-app/queries/__generated__/sectionTopLeadSecondaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Headline,
  LeadingImage,
  Primary,
  StyledContentCoverImage,
  StyledEntityLink,
  StyledHomeFollowButton,
  Summary,
  Topic,
  TopicContainer,
} from "./styles";

export type Props = {
  reference: sectionTopLeadSecondaryContentItemContent$key;
  withBookmark?: boolean;
  withComment?: boolean;
  withVideoOverlay?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      customQueryParameters,
      onClick,
      reference: reference_,
      withBookmark = false,
      withComment = false,
      withVideoOverlay = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment sectionTopLeadSecondaryContentItemContent on Content {
          entityId
          urlAlias
          topics {
            ...followButtonBase
          }
          ...entityLink
          ...contentCoverImageContent @arguments(withSize1200x800: true, withSize540x360: true)
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withTopicLink: true
              withHeadline: true
              withSummary: true
              withActionBar: true
              withSponsorTag: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, { type: "main" });

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);
    const topic = first(content?.topics ?? []);
    const currentPageType = useCurrentPageType();

    return (
      <Container className={className} ref={reference}>
        <LeadingImage>
          <StyledEntityLink
            reference={content}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
          >
            <StyledContentCoverImage
              reference={content}
              responsiveVariants={{
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              }}
            />
            {withVideoOverlay &&
              providers.video?.durationOverlay({
                iconSize: "large",
                variant: "homepage",
              })}
          </StyledEntityLink>
        </LeadingImage>
        <Primary>
          <TopicContainer>
            <Topic>{providers.topicLink?.()}</Topic>
            {topic && (
              <StyledHomeFollowButton
                buttonVariant="square"
                reference={topic}
                source={
                  [PageType.Section, PageType.SubSection].includes(currentPageType)
                    ? "Section"
                    : "Homepage_click"
                }
                type={FollowType.Topic}
              />
            )}
          </TopicContainer>

          <StyledEntityLink
            query={customQueryParameters}
            reference={content}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
            </LinkHighlightArea>
          </StyledEntityLink>

          {isSponsorArticle ? (
            providers.sponsorTag?.({ variant: "home" })
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                isBookmarkVisible: withBookmark ?? false,
                isCommentsVisible: withComment ?? false,
              })}
            </ActionBar>
          )}
        </Primary>
      </Container>
    );
  },
);

Component.displayName = "ContentItemSectionTopLeadSecondary";

export const ContentItemSectionTopLeadSecondary = withHighlightedHeadline(Component);
