import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import LogoPlus from "scmp-app/components/plus/plus-widget/logo-plus.svg";
import { PlusWidgetQuotes } from "scmp-app/components/plus/plus-widget/plus-widget-quotes";

export const Container = styled.div`
  display: grid;
  grid:
    "logo-plus" min-content
    "intro" min-content
    "quote" min-content
    "sample-content" min-content
    / minmax(0, 1fr);
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    grid:
      "logo-plus logo-plus" min-content
      "intro sample-content" min-content
      "quote sample-content" 1fr
      / minmax(0, 1fr) minmax(0, 1fr);

    column-gap: 24px;
  }
`;

export const StyledLogoPlus = styled(LogoPlus)`
  grid-area: logo-plus;

  margin-block-end: 24px;
`;

export const Intro = styled.div`
  grid-area: intro;

  margin-block-end: 24px;
  border-block-end: 1px solid rgba(255, 255, 255, 0.3);

  padding-block-end: 24px;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    inline-size: 100%;
    margin-block-end: 0;
    padding-block-end: 24px;
    padding-inline-end: 24px;
    border-block-end: 0;
    border-inline-end: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const IntroText = styled.div`
  margin-block-end: 12px;

  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.75px;
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    margin-block-end: 24px;
  }
  span {
    color: #8ab2ff;
    font-weight: 700;
  }
`;

export const StyledPlusWidgetQuotes = styled(PlusWidgetQuotes)`
  grid-area: quote;
`;

export const Title = styled.div`
  margin-block-end: 20px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;
`;

export const StyledBaseLink = styled(BaseLink)`
  color: #ffffff;
`;

export const SampleContentItem = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${fontRobotoCondensed};
`;

export const SampleContentType = styled.div`
  margin-block-end: 4px;

  color: #8ab2ff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14.06px;
  text-transform: uppercase;
`;

export const SampleContentHeadline = styled.div`
  margin-block-end: 8px;

  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`;

export const SampleContentImage = styled.img`
  inline-size: 100%;
  margin-block-start: 8px;

  border-radius: 4px;

  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    display: inline-block;

    inline-size: 100%;
  }
`;

export const SampleContentButtonLink = styled.div`
  inline-size: 136px;
  padding-block: 4px;
  padding-inline: 8px;

  color: #8ab2ff;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.41px;

  border: 1px solid #8ab2ff;
  border-radius: 4px;

  transition:
    color 0.2s,
    border-color 0.2s;

  &:hover {
    color: #ffffff;

    border-color: #ffffff;
  }
`;

export const SampleContent = styled.div`
  grid-area: sample-content;
`;

export const SampleContentItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  min-block-size: 497px;

  ${SampleContentItem} {
    &:not(:last-child) {
      padding-block-end: 20px;
      border-block-end: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    min-block-size: 632px;
  }
`;
