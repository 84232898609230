import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { Figure } from "scmp-app/components/content/content-cover-image/styles";
import { ContentSubHeadlineContainer } from "scmp-app/components/content/content-sub-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const StyledEntityLink = styled(EntityLink)`
  position: relative;

  display: block;
`;

export const Container = styled.div`
  display: grid;
  grid:
    "leading-image" min-content
    "." 12px
    "left" min-content
    "right" min-content
    / 100%;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "leading-image leading-image" min-content
      ". ." 24px
      "left right" min-content
      / minmax(0, 1fr) calc((100% - 64px) / 3);

    column-gap: 32px;
  }
`;

export const LeadingImage = styled.div`
  grid-area: leading-image;

  ${props => props.theme.breakpoints.up("tablet")} {
    position: relative;

    overflow: hidden;
    aspect-ratio: 250/99;
    ${Figure} {
      position: absolute;

      block-size: auto;
    }
  }
`;

export const Left = styled.div`
  grid-area: left;
`;

export const Right = styled.div`
  grid-area: right;
`;

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-block-end: 12px;
`;

export const Headline = styled.div`
  color: #000000;
  font-weight: 300;
  font-size: 24px;
  font-family: ${fontMerriweather};
  line-height: 140%;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 28px;
  }
`;

export const LiveTagContainer = styled.div`
  margin-block-end: 8px;

  :empty {
    margin-block-end: 0;
  }
`;

export const Summary = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;

    margin-block-end: 12px;

    color: #6f6f6f;
    font-weight: 400;
    font-size: 14px;
    font-family: ${fontRoboto};
    line-height: 130%;
  }

  ${ContentSubHeadlineContainer} {
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;

      padding-inline-start: 13px;
    }
  }
`;

export const ActionBar = styled.div`
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
`;

export const TailContent = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-block-start: 20px;
    border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

    padding-block-start: 20px;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    *:first-child {
      margin-block-start: 0;
    }
  }
`;
