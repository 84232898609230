import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";

export const Container = styled.div`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(255, 255, 255, 0.3);
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    margin-block-end: 0;
    margin-block-end: 0;

    border-block-end: 0;
    border-inline-end: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const Wrapper = styled.div`
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    margin-inline-end: 24px;
    padding-block-start: 24px;
    border-block-start: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const Quote = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontMerriweather};
  line-height: 140%;
  text-align: center;
`;

export const Author = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const AuthorImage = styled.img`
  max-inline-size: 40px;
`;

export const AuthorName = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

export const StyledSwiper = styled(Swiper)`
  overflow-y: hidden;

  & {
    .swiper-wrapper {
      block-size: 100%;
    }
    .swiper-pagination.swiper-pagination-horizontal {
      /* stylelint-disable-next-line */
      top: unset;
      bottom: 0;
    }
  }
`;
