import {
  findFirstNonEmptyString,
  notEmpty,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { focusArticleHomeFocusArticleQuery$key } from "scmp-app/queries/__generated__/focusArticleHomeFocusArticleQuery.graphql";

import { checkIsLiveArticle, getArticleTitle, getPodcastTitle, getVideoTitle } from "./helpers";
import { Container, LiveDot, StyledContentItemHomeFocus, Title } from "./styles";

type Props = {
  reference: focusArticleHomeFocusArticleQuery$key;
};

type Settings = {
  custom_text?: string;
};

export const HomeFocusArticle: FunctionComponent<Props> = ({ reference }) => {
  const data = useFragment(
    graphql`
      fragment focusArticleHomeFocusArticleQuery on Query
      @argumentDefinitions(focusQueueName: { type: "String!" }) {
        focus: queue(filter: { name: $focusQueueName }) {
          settings
          items(first: 1) {
            edges {
              node {
                ...homeFocusContentItemContent
                ...helpersGetArticleTitleArticle
                ...helpersGetPodcastTitlePodcast
                ...helpersGetVideoTitleVideo
                ...helpersCheckIsLiveArticle
                ... on Content {
                  entityId
                  urlAlias
                }
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const article = first(data?.focus?.items?.edges)?.node;

  const title = useMemo(() => {
    const customText = (data?.focus?.settings as Settings)?.custom_text ?? "";
    const videoTitle = getVideoTitle(article);
    const articleTitle = getArticleTitle(article);
    const podcastTitle = getPodcastTitle(article);
    return findFirstNonEmptyString(customText, podcastTitle, videoTitle, articleTitle, "Focus");
  }, [article, data?.focus?.settings]);

  const { isLive = false, isLiveArticle = false } = notEmpty(article)
    ? checkIsLiveArticle(article)
    : {};

  const impressionGA4Event = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        block_section: title,
        list_of_articles: article?.entityId,
        page_type: "homepage",
        widget_name: "flexi_unit",
      },
      subcategory: "widget",
    }),
    [article?.entityId, title],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: impressionGA4Event,
      options: { isSendGA4Tracking: true, shouldSendOnce: true },
    });

  if (!article) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.FlexiUnitFocus,
      }}
    >
      <Container>
        <Title>
          {isLiveArticle && <LiveDot $isLive={isLive} />}
          <span>{title}</span>
        </Title>
        <StyledContentItemHomeFocus
          ref={captureTrackImpressionEventTargetElement}
          reference={article}
          topicLinkVariant={{ type: "main" }}
          onClick={entityId => {
            sendGA4Tracking({
              action: "click",
              category: "recirculation",
              customized_parameters: {
                article_id: entityId,
                block_section: title,
                destination_url: getAbsoluteUrl(article?.urlAlias),
                page_type: "homepage",
                widget_name: "flexi_unit",
              },
              subcategory: "widget",
            });
          }}
        />
      </Container>
    </BaseLinkContextProvider>
  );
};

HomeFocusArticle.displayName = "HomeFocusArticle";
