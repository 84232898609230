import type { FunctionComponent, ReactNode } from "react";

import { CallToActionButton, StyledBaseLink } from "./styles";

type Props = {
  children: ReactNode;
  className?: string;
  onClick: (destinationUrl: string) => void;
};

export const MyNewsLink: FunctionComponent<Props> = ({ children, className, onClick }) => (
  <StyledBaseLink
    anchorProps={{ "aria-label": "my news" }}
    as="/mynews"
    className={className}
    pathname="mynews"
    onClick={() => onClick("/mynews")}
  >
    <CallToActionButton>{children}</CallToActionButton>
  </StyledBaseLink>
);

MyNewsLink.displayName = "MyNewsLink";
