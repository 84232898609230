import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { Spacer } from "scmp-app/components/common/spacer";
import {
  DateContainer,
  LocationContainer,
  MiddleContainer,
  MonthContainer,
  Container as NewsAgendaContentContainer,
  TitleContainer,
} from "scmp-app/components/plus/home/agenda-block/content/styles";
import { AgendaItem } from "scmp-app/components/plus/home/agenda-block/item";
import { Container as NewsAgendaFilterContainer } from "scmp-app/components/plus/news-agenda/filter/styles";

export const StyledAgendaItem = styled(AgendaItem)`
  inline-size: auto;

  background-color: #ffffff;

  &:hover {
    ${TitleContainer} {
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  margin-block-start: 16px;

  color: #000000;

  ${NewsAgendaFilterContainer} {
    column-gap: 0;
  }

  ${NewsAgendaContentContainer} {
    grid:
      "left title link" min-content
      "left right right" min-content
      / 36px 1fr 24px;

    padding: 8.61px;
  }

  ${TitleContainer} {
    margin-block-end: 5.74px;
    padding-inline-end: 0;

    font-size: 16px;
    line-height: 18.75px;
    border-inline-end: 0;
  }

  ${MiddleContainer} {
    border-inline-end: 0;
  }

  ${DateContainer}, ${LocationContainer} {
    font-size: 12px;
    line-height: 14.06px;
  }

  ${LocationContainer} {
    margin-block-start: 2.87px;
  }

  ${MonthContainer} {
    font-size: 10px;
    line-height: 11.72px;
  }

  ${Spacer} {
    display: none;
  }
`;

export const PlusNewsAgendaItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  inline-size: 100%;
  ${StyledAgendaItem}:not(:last-child) {
    margin-block-end: 12px;
  }
`;

export const MoreOnLink = styled(BaseLink)`
  display: none;
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    display: flex;
    gap: 4px;
    align-items: center;

    margin-block-start: 16px;

    color: #8ab2ff;
    font-weight: 700;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    line-height: 16.41px;

    &:hover {
      text-decoration: underline;
    }
  }
`;
