import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemHomeLatest } from "scmp-app/components/content/content-item-render/variants/home-latest";

export const Title = styled(BaseLink)`
  margin-block-end: 20px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
`;

export const LatestItems = styled.div`
  display: grid;
  grid-row-gap: 20px;
  border-block-end: 1px solid #0000001a;

  padding-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px 28px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid-template-columns: 1fr;
  }
`;

export const StyledContentItemHomeLatest = styled(ContentItemHomeLatest)``;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid #00000033;

  ${StyledContentItemHomeLatest}:not(:last-child) {
    border-block-end: 1px solid #0000001a;

    padding-block-end: 20px;
    ${props => props.theme.breakpoints.up("tablet")} {
      border-block-end: 0;

      padding-block-end: 0;
    }
    ${props => props.theme.breakpoints.up("homeDesktop")} {
      padding-block-end: 20px;
      border-block-end: 1px solid #0000001a;
    }
  }
`;

export const MoreLink = styled(BaseLink)`
  margin-block-start: 20px;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-size: 14px;

  border: 1px solid #4585ff;
  border-radius: 2px;
`;
