import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { plusWidgetQuery$key } from "scmp-app/queries/__generated__/plusWidgetQuery.graphql";

import { PlusWidgetHasAccess } from "./plus-widget-has-access";
import { PlusWidgetWithoutAccess } from "./plus-widget-without-access";
import {
  Container,
  ContentContainer,
  ContentWrapper,
  PlusWidgetPlaceholder,
  PlusWidgetPlaceholderTablet,
} from "./styles";

export type PlusWidgetsSampleContentType =
  | "homepage"
  | "hong-kong"
  | "china"
  | "economy"
  | "business"
  | null;

type Props = {
  className?: string;
  pageType: "homepage" | "section" | "topic";
  reference: plusWidgetQuery$key;
  sampleContent: PlusWidgetsSampleContentType;
};

export const PlusWidget: FunctionComponent<Props> = ({
  className,
  pageType,
  reference,
  sampleContent,
}) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const hasScmpPlusAccessRight = asyncRosettaState?.result?.hasScmpPlusAccessRight ?? false;
  const data = useFragment(
    graphql`
      fragment plusWidgetQuery on Query
      @argumentDefinitions(
        applicationId: { type: "String!" }
        dailyPulseTypeUuId: { type: "String!" }
        highlightQueueName: { type: "String!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
      ) {
        ...plusWidgetHasAccessQuery
          @arguments(
            applicationId: $applicationId
            dailyPulseTypeUuId: $dailyPulseTypeUuId
            highlightQueueName: $highlightQueueName
            plusNewsAgendaEndDate: $plusNewsAgendaEndDate
            plusNewsAgendaStartDate: $plusNewsAgendaStartDate
            plusNewsAgendaTypes: $plusNewsAgendaTypes
          )
      }
    `,
    reference,
  );

  const placeholder = useMemo(
    () => (
      <>
        <PlusWidgetPlaceholder />
        <PlusWidgetPlaceholderTablet />
      </>
    ),
    [],
  );

  return (
    <Container className={className}>
      <ClientSideSuspense fallback={placeholder}>
        {asyncRosettaState?.status === "success" ? (
          <ContentContainer>
            <ContentWrapper>
              {hasScmpPlusAccessRight ? (
                <PlusWidgetHasAccess pageType={pageType} reference={data} />
              ) : (
                <PlusWidgetWithoutAccess sampleContent={sampleContent} />
              )}
            </ContentWrapper>
          </ContentContainer>
        ) : (
          placeholder
        )}
      </ClientSideSuspense>
    </Container>
  );
};

PlusWidget.displayName = "PlusWidget";
